import React from "react";
import ReactDOM from "react-dom";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";

import Home from "components/homepage";
import ErrorBoundary from "components/error/ErrorBoundary";
import AlertsProvider from "components/shared/AlertsProvider";


const queryClient = new QueryClient();

function HomeApp() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AlertsProvider>
          <Home/>
        </AlertsProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

ReactDOM.render(
  HomeApp(),
  document.getElementById("app"),
);
